module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫","short_name":"HOME TO ALL 我們也可以這樣生活 / 台北社會住宅藝術計畫","start_url":"/","background_color":"#F3EDDC","theme_color":"#3E4857","display":"standalone","icon":"src/images/fav_icon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-analytics/gatsby-browser.js'),
      options: {"plugins":[],"trackingId":"UA-164225746-1","head":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
